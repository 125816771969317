import React from 'react';

const Image = () => {
  return (
    <div className='image-container' style={{ width: '7vw', height: '7vw', borderRadius: '50%', overflow: 'hidden' }}>
      <img src='logo512.png' alt='Company Logo' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </div>
  );
};

export default Image;


