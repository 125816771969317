/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 basin.glb 
*/


import { useGLTF, PerspectiveCamera } from '@react-three/drei'

function Basin(props) {
  const { nodes, materials } = useGLTF('basin.glb')
  return (
    <group {...props} dispose={null}>
      <PerspectiveCamera makeDefault={false} far={100} near={0.1} fov={22.895} position={[7.359, 32.205, 6.926]} rotation={[-0.627, 0.71, 0.441]} />
      <mesh geometry={nodes.b1.geometry} material={materials['Material.002']} />
      <mesh geometry={nodes.b2.geometry} material={materials['Material.001']} position={[1.364, -0.049, -29.364]} />
    </group>
  )
}

useGLTF.preload('basin.glb')

export default Basin;
