import React from 'react'

const Academic = () => {
  return (
    <div>
        <h2>My Academic Path</h2>
        <p>* International Language & Business Center</p>
        <p>* Guarantee International College</p>
        <p>* Hinthar Education</p>
        <p>* Gusto University</p>
        <p>* Gloucestershire University</p>
    </div>
  )
}

export default Academic