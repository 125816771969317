import React from 'react'

const Ilbc = () => {
  return (
    <div className='image-container' style={{ width: '6vw', height: '6vw', borderRadius: '50%', overflow: 'hidden' }}>
<img src='ilbc.png' alt='Company Logo' style={{ width: '100%', height: '100%', objectFit: 'contain' }} />

  </div>
  )
}

export default Ilbc