import React from 'react'

const Bsc = () => {
  return (
    <div>
        <p>BSc.(Hons)Computing (UK)</p>
    </div>
  )
}

export default Bsc