import './App.css';

import { Stage, OrbitControls} from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import Basin from './components/Basin.jsx'





function App() {

  return (
    <>



    <div className='container'>
      <Canvas style={{width:'100%', height:'100vh'}}  frameloop="demand" shadows camera={{ position: [0, 100, 150], fov: 40, zoom: 0.8 }}>
      <OrbitControls/>
      <Stage preset='portrait' environment="forest" intensity={0.9}>
          
      <Basin rotation={[0, Math.PI / 2, 0]}/>
      </Stage>
      </Canvas>
    </div>
    </>
  );
}

export default App;
